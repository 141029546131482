import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectLanguageOptions } from 'ducks/server/settings';

import styles from './BookingWidgetHeader.module.css';

export const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const languageOptions = useSelector(selectLanguageOptions);

  const selectedLanguageISO = i18n?.language || '';

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const urlParts = window.location.href.split('?');
    const searchParams = new URLSearchParams(urlParts.length > 1 ? urlParts[1] : '');
    searchParams.set('lng', event.target.value);

    window.location.href = `${urlParts[0]}?${searchParams.toString()}`;
  };

  return (
    <label className={clsx(styles['header__lang'])}>
      <img src="/static/images/family/ic_lang.svg" />
      <select
        key={i18n?.language}
        name="lang"
        value={selectedLanguageISO}
        onChange={handleLanguageChange}
      >
        {languageOptions.map((languageOption) => (
          <option key={languageOption.iso} value={languageOption.iso}>
            {languageOption.name}
          </option>
        ))}
      </select>
    </label>
  );
};
