import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { useEssentialPageLinks } from 'hooks/useEssentialPageLinks';
import { selectLanguageOptions } from 'ducks/server/settings';
import { useQueryString } from 'hooks/useQueryString';
import { useUrl } from 'hooks/useUrl';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import { LanguageSelect } from './LanguageSelect';
import { LoginItem } from './LoginItem';
import { MobileLoginItem } from './MobileLoginItem';
import { toHeaderSettingsShape } from './util';
import styles from './BookingWidgetHeader.module.css';
import { useHeaderDescriptionTagType } from 'hooks/useHeaderDescriptionTagType';

const BookingWidgetHeader = () => {
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);
  const { t } = useTranslation();

  const router = useRouter();

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const settings = toHeaderSettingsShape(apiSettings);

  const myPageFeatureEnabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription) =>
          subscription.key === 'feature-guest-my-page' && subscription.status === 'ACTIVE'
      ) ?? false
  );

  const customerLedgerFeatureEnabled = useCustomerLedgerEnabled();

  const showReservationsPage =
    myPageFeatureEnabled &&
    !!apiSettings?.guest_my_page_settings?.allow_viewing_reservation_details &&
    !customerLedgerFeatureEnabled;

  const languageOptions = useSelector(selectLanguageOptions);

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mobileMenuIsOpen) {
      document.body.classList.remove(styles['c-header__fixed']);
    } else {
      document.body.classList.add(styles['c-header__fixed']);
    }

    return () => document.body.classList.remove(styles['c-header__fixed']);
  }, [mobileMenuIsOpen]);

  const productListEnabled =
    Boolean(apiSettings.enable_product_list_page) && !router.asPath?.includes('qrreader');

  const showMenus = !router.asPath?.includes('qrreader');

  const essentialPageLinks = useEssentialPageLinks();

  const configQueryString = useQueryString();

  const reservationsUrl = useUrl('/reservations/verify');

  const headerDescriptionTag = useHeaderDescriptionTagType();

  let productListPath = useApiKeyInPaths ? `/${apiKey}` : '/';
  if (configQueryString) {
    productListPath = `${productListPath}?${configQueryString}`;
  }

  const handleLanguageChange = (newLanguage: string) => {
    const urlParts = window.location.href.split('?');
    const searchParams = new URLSearchParams(urlParts.length > 1 ? urlParts[1] : '');
    searchParams.set('lng', newLanguage);

    window.location.href = `${urlParts[0]}?${searchParams.toString()}`;
  };

  return (
    <header className={clsx(styles['header'])}>
      <div className={clsx(styles['header__desc'])}>
        <p>{settings.supplierName}</p>
        {headerDescriptionTag === 'h1' ? (
          <h1>{settings.headerDescription}</h1>
        ) : (
          <div>{settings.headerDescription}</div>
        )}
      </div>
      <div className={clsx(styles['header__main'])}>
        <Link
          href={productListEnabled ? productListPath : ''}
          className={styles['header__main__logo']}
        >
          <img src={settings.logoURL} />
        </Link>
        {showMenus && (
          <>
            <LanguageSelect />
            {customerLedgerFeatureEnabled && <LoginItem />}
            <input
              className={styles['c-header__flg']}
              type="checkbox"
              id="menu"
              checked={mobileMenuIsOpen}
              onChange={() => {
                setMobileMenuIsOpen(!mobileMenuIsOpen);
              }}
            />
            <label className={styles['c-header__menu']} htmlFor="menu">
              <span />
            </label>

            <div className={styles['c-header__nav']}>
              <ul className={clsx(styles['c-header__nav__list'], 'maincolor-txt')}>
                {showReservationsPage && (
                  <li className={styles['c-header__nav__list__item']}>
                    <Link href={reservationsUrl}>{t('Reservations')}</Link>
                  </li>
                )}
                <li className={styles['c-header__nav__list__item']}>
                  <div className={styles['c-header__nav__list__item__childrenMenu']}>
                    {languageOptions.length > 1 && (
                      <>
                        <p>{t('Language')}</p>
                        <ul className="maincolor-border" style={{ left: 'calc(-220px)' }}>
                          {languageOptions.map((languageOption) => (
                            <li key={languageOption.iso}>
                              <a
                                onClick={() => {
                                  handleLanguageChange(languageOption.iso);
                                }}
                              >
                                {languageOption.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </li>
                {(essentialPageLinks || []).length > 0 && (
                  <li className={styles['c-header__nav__list__item']}>
                    <div className={styles['c-header__nav__list__item__childrenMenu']}>
                      <p>{t('Info')}</p>
                      <ul className="maincolor-border" style={{ left: 'calc(-220px)' }}>
                        {essentialPageLinks.map((link, idx) => (
                          <li key={idx}>
                            <Link href={link.url}>{link.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
                {customerLedgerFeatureEnabled && <MobileLoginItem />}
              </ul>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default BookingWidgetHeader;
